<template>
  <b-card title="Danh sách bài viết thể loại Hướng dẫn kỹ thuật">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block"
            placeholder="Từ khóa"
            type="text"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span v-if="props.column.field === 'title'" class="text-nowrap"
        >
            <router-link :to="{name: 'manage-hdkt-edit', params: {id: props.row.id}}">
              <span class="text-nowrap">{{ props.row.title }}</span>
            </router-link>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'page_id'">
          <b-badge :variant="statusVariant(props.row.page_id)">
            {{ props.row.page_id }}
          </b-badge>
        </span>

        <!-- Column: created_at -->
        <span v-else-if="props.column.field === 'created_at'">
              {{ new Date(props.row.created_at).toLocaleString() }}
            </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-2"
            type="submit"
            variant="danger"
            @click="deleteConfirmation(props.row.id, props.index)"
          >Xóa</b-button>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {BBadge, BCard, BButton, BFormGroup, BFormInput,} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from "../../../@core/components/toastification/ToastificationContent";

export default {
  components: {
    BCard,
    VueGoodTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BButton
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Tên',
          field: 'title',
        },
        {
          label: 'Ngày tạo',
          field: 'created_at',
        },
        {
          label: 'Cập nhật',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/search/post?is_research_achievements=0&page_id=HDKT')
      .then(res => {
        this.rows = res.data.data
      })
  },
  methods: {
    deleteHdkt(id, idx) {
      this.$http.delete(`/posts/${id}`)
        .then(response => {
          console.log(response.data)
          this.rows.splice(idx, 1)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Cập nhật thành công👋',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
              variant: 'danger',
            },
          })
        })
    },
    deleteConfirmation(id, idx){
      this.$bvModal.msgBoxConfirm('Hành động này không thể khôi phục. Vẫn xóa?', {
        title: 'Xác nhận muốn xóa dữ liệu',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Xóa',
        cancelTitle: 'Hủy',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      })
        .then(value => {
          if (value) {
            this.deleteHdkt(id, idx)
          }
        })
        .catch(err => {
          console.log(err, id)
        })
    }
  }
}
</script>
